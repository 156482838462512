import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { removeWithKeyFromlocalStorage } from '../utils/helpers/helpers';

//hello

export const baseQuery = fetchBaseQuery({
    baseUrl: 'https://backend.dentapp.online/api',

    prepareHeaders(headers, { getState }) {
        const { token } = getState().auth;

        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
            headers.set(
                'Access-Control-Allow-Headers',
                'Content-Type, Authorization'
            );
            if (!headers.has('Content-Type')) {
                headers.set('Content-Type', 'application/json');
            }
            return headers;
        }
    },
});

export const baseQueryWithReauth = async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);
    if (result?.error?.status === 403 || result?.response?.status === 403) {
        removeWithKeyFromlocalStorage();
    } else if (result?.error?.status === 401 || result?.response?.status) {
    }
    return result;
};
